import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import qs from "qs";

import BaiduMap from "vue-baidu-map";

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "lIXC8d0tvmYlv7FjVKIhrCRo5pDbf8RO",
});
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
axios.defaults.baseURL = "http://101.37.167.82:1110/api";
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
