<template>
  <div class="index">
    <div class="head1">
      <!-- 顶部导航栏 -->
      <Head />
    </div>
    <div class="center" >
      <!-- 中间 -->
      <div class="banner1"></div>
      <div class="title" v-show="qiehuan">
        <div class="title_1">智能硬件</div>
        <div class="title_xian"></div>
        <div class="title_2">Intelligent Hardware</div>
      </div>
      <div class="imgs">
        <div class="left-title">【智能硬件场景应用】</div>
        <img :src="require('@/assets/znyj1.png')">
        <div class="left-title">【机构养老场景】</div>
        <img :src="require('@/assets/znyj2.png')">
        <div class="left-title">【社区/居家养老场景】</div>
        <img :src="require('@/assets/znyj3.png')">
      </div>
      <!--<div class="title" v-show="!qiehuan" style="position: relative;margin-bottom:100px">-->
      <!--  <div-->
      <!--    style="-->
      <!--      width: 94px;-->
      <!--      height: 30px;-->
      <!--      background: #f47506;-->
      <!--      font-size: 16px;-->
      <!--      font-family: Microsoft YaHei;-->
      <!--      font-weight: 400;-->
      <!--      color: #ffffff;-->
      <!--      position: absolute;-->
      <!--      left: 360px;-->
      <!--      padding-top: 5px;-->
      <!--      cursor: pointer;-->
      <!--    "-->
      <!--    @click="fanhui"-->


      <!--  >-->
      <!--    返回列表-->
      <!--  </div>-->
      <!--  <div class="title_1"  id="lai">产品介绍</div>-->

      <!--  <div class="title_xian"></div>-->
      <!--  <div class="title_2">Product Introduction</div>-->
      <!--</div>-->
      <!--<div class="nei" v-show="qiehuan">-->
      <!--  <div class="cp" @click="xq(1)" >-->
      <!--    <div class="img"><img src="../assets/t15.png" alt="" style="width: 27%"/></div>-->
      <!--    <div class="title">智能网关</div>-->
      <!--  </div>-->
      <!--  <div class="cp" @click="xq(2)">-->
      <!--    <div class="img"><img src="../assets/t16.png" alt="" style="width: 55%"/></div>-->
      <!--    <div class="title">一键呼叫</div>-->
      <!--  </div>-->
      <!--  <div class="cp" @click="xq(3)">-->
      <!--    <div class="img"><img src="../assets/t17.png" alt="" style="width: 37%"/></div>-->
      <!--    <div class="title">红外人体感应</div>-->
      <!--  </div>-->
      <!--  <div class="cp" @click="xq(4)">-->
      <!--    <div class="img">-->
      <!--      <img src="../assets/t18.png" alt="" style="margin-top: 80px" />-->
      <!--    </div>-->
      <!--    <div class="title">智能离床带</div>-->
      <!--  </div>-->
      <!--  <div class="cp" @click="xq(5)">-->
      <!--    <div class="img"><img src="../assets/t19.png" alt="" /></div>-->
      <!--    <div class="title">4G智能手环</div>-->
      <!--  </div>-->
      <!--  <div class="cp" @click="xq(6)">-->
      <!--    <div class="img"><img src="../assets/t20.png" alt="" /></div>-->
      <!--    <div class="title">4G定位器</div>-->
      <!--  </div>-->
      <!--  <div class="cp" @click="xq(7)">-->
      <!--    <div class="img"><img src="../assets/t21.png" alt="" /></div>-->
      <!--    <div class="title">视频监控（萤石）</div>-->
      <!--  </div>-->
      <!--  <div class="cp" style="margin-right: 420px" @click="xq(8)">-->
      <!--    <div class="img">-->
      <!--      <img src="../assets/t22.png" alt="" style="margin-top: 50px" />-->
      <!--    </div>-->
      <!--    <div class="title">呼叫语音显示终端</div>-->
      <!--  </div>-->
      <!--</div>-->
      <!--<znwg v-show="xq1" />-->
    </div>

    <!-- 底部 -->
    <Foot />
  </div>
</template>

<script>
import Head from "../components/head.vue";
import Foot from "../components/foot.vue";
// import znwg from "../components/product/znwg.vue";

export default {
  components: {
    Head,
    Foot,
    // znwg,
  },
  data() {
    return {
      qiehuan: true,
      xq1: false,
      xq2: false,
      xq3: false,
      xq4: false,
      xq5: false,
      xq6: false,
      xq7: false,
      xq8: false,
    };
  },
   mounted() {
    console.log(this.$route.params.bianhao);
  if(this.$route.params.bianhao){
    this.xq(this.$route.params.bianhao)
  }

setTimeout(() => {
  let toElement = document.getElementById("lai");

      if (this.$route.params.bianhao) {
        toElement.scrollIntoView();
      }
}, 100);




  },
  methods: {
    xq(e) {
      this.qiehuan = !this.qiehuan;
      switch (e) {
        case 1:
          this.xq1 = !this.xq1;
          break;
        case 2:
          this.xq2 = !this.xq2;
          break;
        case 3:
          this.xq3 = !this.xq3;
          break;
        case 4:
          this.xq4 = !this.xq4;
          break;
        case 5:
          this.xq5 = !this.xq5;
          break;
        case 6:
          this.xq6 = !this.xq6;
          break;
        case 7:
          this.xq7 = !this.xq7;
          break;
        case 8:
          this.xq8 = !this.xq8;
          break;

        default:
          console.log("失败");
      }
    },
    fanhui() {
      this.qiehuan = true;

      this.xq1 = false;
      this.xq2 = false;
      this.xq3 = false;
      this.xq4 = false;
      this.xq5 = false;
      this.xq6 = false;
      this.xq7 = false;
      this.xq8 = false;
    },
  },
};
</script>
<style scoped lang="scss">
.imgs {
  width: 1181px;
  margin: 0 auto;
  img {
    width: 100%;
    margin-top: 30px;
  }
  .left-title {
    margin-top: 50px;
    font-family: "Microsoft YaHei";
    font-size: 25px;
    text-align: left;
  }
}
.center {
  width: 100%;
  //.nei {
  //  width: 1181px;
  //  margin: 0 auto;
  //  display: flex;
  //  flex-wrap: wrap;
  //  justify-content: space-between;
  //  .cp {
  //    .img {
  //      width: 339px;
  //      height: 214px;
  //      background: #ffffff;
  //      box-shadow: -2px 2px 13px 0px rgba(42, 42, 42, 0.55);
  //      text-align: center;
  //      transition: all 0.6s;
  //      cursor: pointer;
  //      img {
  //        margin-top: 30px;
  //      }
  //    }
  //    .title {
  //      width: 160px;
  //      height: 19px;
  //      font-size: 18px;
  //      font-family: Microsoft YaHei;
  //      font-weight: bold;
  //      color: #2a2a2a;
  //      margin: 0 auto;
  //      margin-top: 30px;
  //      margin-bottom: 80px;
  //    }
  //  }
  //  .img:hover {
  //    transform: scale(1.1);
  //  }
  //}
}
.banner1 {
  width: 100%;
  height: 533px;
  background-image: url(../assets/banner.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-origin: content-box;
}
.title {
  margin-top: 50px;
  margin-bottom: 50px;
  .title_1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #272727;
  }
  .title_xian {
    margin: 0 auto;
    margin-top: 5px;
    width: 57px;
    height: 2px;
    background: #272727;
  }
  .title_2 {
    margin-top: 5px;
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
    color: #828282;
  }
}
.index {
  text-align: center;
  background: #f5f8fb;
  overflow: hidden;
}
.head1 {
  text-align: center;
  background: #ffffff;
}
</style>
